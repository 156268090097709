.content {
  max-width: 1180px;

}

@media only screen and (max-width: 1000px) {
  /* om te voorkomen dat navigatie over logo gaat */
  .logo-div {
    left: 0px;
  }
}

@media only screen and (max-width: 993px) {
  .header-block-orange, .header-block-green, .header-block-blue, .header-block-banner {
    left: 0px;
  }

  .custom-table-filters {
    width: 70% !important;
  }
}

@media only screen and (max-width: 880px) {
  /* Google maps responsive maken */
  .google-map {
    width: 100% !important;
    height: 200px !important;
  }

  .custom-table-filters {
    width: 65% !important;
  }
}

@media only screen and (max-width: 767px) {
  table.modules td {
    display: block;
    width: 100%;
    float: left;
  }

  table.modules {
    width: 100%;
  }

  .custom-table {
    width: 100% !important;
  }

  .content {
    padding: 14px 14px 56px 14px;
  }

  .content-login {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 0 7px;
    height: 28px;
    line-height: 28px;
    margin-left: 5px;
  }

  .content-login-profile {
    color: #FFF;
  }

  .content-login-icon:before {
    display: block;
  }

  .content-login-text {
    display: none;
  }

  .background-image-puzzle {
    background-image: none;
  }

  .lokatie-op-kaart {
    width: 100% !important;
    float: none !important;
  }

  .logo-div {
    margin: 9px 0 0 15px !important;
  }

  .container {
    margin: auto !important;
    width: 100% !important;
  }

  input[type="file"] {
    font-size: 12px;
  }

  .site {
    background: none;
  }
}

@media only screen and (max-width: 400px) {
  .detail-media-viewer {
    height: 350px;
  }

  .btn-search {
    background-image: none;
  }
}

@media only screen and (max-width: 360px) {
  .default-button-large {
    padding: 5px 14px !important;
  }

  .chzn-drop {
    width: 100% !important;
  }

  div.token-input-dropdown {
    width: 90% !important;
  }
}

@media only screen and (min-device-width: 0px) and (max-device-width: 720px) {
  .custom-textfield-autocomplete-btn {
    display: none !important;
  }
}
